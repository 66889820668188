import localStyles from "./EmployeeList.module.css";
import cx from "classnames";
import checkedIcon from "assets/images/7.svg";
import { AvatarOrInitials } from "components/utils/userAvatar/UserAvatar";
import { EmployeeKind } from "../employeeKind/EmployeeKind";
import { Checkbox } from "components/utils";
import { Employee as UpholsteryEmployee } from "api/upholsteries/models";
import { Employee } from "api/new-production-plans/models";
import { useListCheckboxes } from "hooks/useListCheckboxes";

interface Props {
  assignedEmployees: Employee[];
  checkboxes: ReturnType<typeof useListCheckboxes>;
  employees: UpholsteryEmployee[];
}

export const EmployeeList = ({ assignedEmployees, checkboxes, employees }: Props) => {
  return (
    <>
      {employees.map(employee => {
        if (assignedEmployees.find(assignedEmployee => assignedEmployee.id === employee.id)) {
          return (
            <div
              aria-disabled={true}
              className={cx(
                localStyles.assignedEmployeeRow,
                "d-flex align-items-center justify-content-between text-color-grey p-2",
              )}
              key={employee.id}
            >
              <div className="d-flex align-items-center">
                <div
                  className={cx(
                    localStyles.checked,
                    "d-flex align-items-center justify-content-center",
                  )}
                >
                  <img alt="Pracownik przypisany" src={checkedIcon} className="" />
                </div>
                <AvatarOrInitials
                  avatarSrc={employee.avatar}
                  firstName={employee.firstName}
                  lastName={employee.lastName}
                  className={cx(localStyles.avatar, localStyles.avatarSmall, "mr-2")}
                />
                <div>
                  <span>{employee.firstName} </span>
                  <span>{employee.lastName}</span>
                </div>
              </div>
              <EmployeeKind employeeKind={employee.kind} />
            </div>
          );
        } else {
          return (
            <div
              className={cx(
                localStyles.employeeRow,
                "d-flex align-items-center justify-content-between p-2",
              )}
              key={employee.id}
            >
              <div className="d-flex align-items-center">
                <div
                  onClick={e => {
                    e.stopPropagation();
                  }}
                  className={localStyles.checkbox}
                >
                  <Checkbox
                    checked={checkboxes.check(employee.id)}
                    name=""
                    onChange={() => checkboxes.toggle(employee)}
                  />
                </div>
                <AvatarOrInitials
                  avatarSrc={employee.avatar}
                  firstName={employee.firstName}
                  lastName={employee.lastName}
                  className={cx(localStyles.avatar, localStyles.avatarSmall, "mr-2")}
                />
                <div className={localStyles.employeeName}>
                  <span>{employee.firstName} </span>
                  <span>{employee.lastName}</span>
                </div>
              </div>
              <EmployeeKind employeeKind={employee.kind} />
            </div>
          );
        }
      })}
    </>
  );
};
