import { EmployeeKind as Kind } from "api/new-production-plans/models";
import localStyles from "./EmployeeKind.module.css";
import cx from "classnames";

interface Props {
  employeeKind: Kind;
}

const employeeKindDict = {
  UPHOLSTERER: "TAPICER",
  SEAMSTRESS: "SZWACZ",
  CUTTER: "KROJCZY",
};

export const EmployeeKind = ({ employeeKind }: Props) => {
  return (
    <div
      className={cx(localStyles.employeeKindLabel, "p-2", {
        [localStyles.upholstererLabel]: employeeKind === "UPHOLSTERER",
        [localStyles.seamstressLabel]: employeeKind === "SEAMSTRESS",
        [localStyles.cutterLabel]: employeeKind === "CUTTER",
      })}
    >
      <span>{employeeKindDict[employeeKind]}</span>
    </div>
  );
};
