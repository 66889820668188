import localStyles from "./EmployeeHeader.module.css";
import styles from "../../../../ProductionPlanDetails.module.css";
import showPdfIcon from "assets/images/161.svg";
import binIcon from "assets/images/81.svg";
import cx from "classnames";
import { Employee, ProductionPlan } from "api/new-production-plans/models";
import { AvatarOrInitials } from "components/utils/userAvatar/UserAvatar";
import { Button } from "components/common";
import { useMutation } from "hooks/useMutation";
import { removeEmployeeFromProductionPlan } from "api/new-production-plans/calls";
import { productionPlansKeys } from "api/new-production-plans/keys";
import { useConfirmModal, useQueryUtils } from "hooks";
import { useQueryClient } from "react-query";

interface Props {
  employee: Employee;
  productionPlanId: string;
}

const employeeKind = {
  UPHOLSTERER: "Tapicer",
  CUTTER: "Krajacz",
  SEAMSTRESS: "Krawiec",
};

export const EmployeeHeader = ({ employee, productionPlanId }: Props) => {
  const { handleMutate, rollback } = useQueryUtils();
  const confirmationModal = useConfirmModal();
  const queryClient = useQueryClient();

  const removeEmployeeMutation = useMutation(
    ({ id, productionPlanId }: { id: number; productionPlanId: string }) =>
      removeEmployeeFromProductionPlan({
        stageManufacturingPlanId: productionPlanId,
        employees: [id],
      }),
    {
      onMutate: ({ id, productionPlanId }: { id: number; productionPlanId: string }) => {
        const prevState = handleMutate<ProductionPlan>(
          productionPlansKeys.productionPlan(productionPlanId),
          draft => {
            draft.employees = draft.employees.filter(el => el.id !== id);
          },
        );
        return prevState;
      },
      onError: (error, _, prevState) => {
        if (prevState) {
          rollback(productionPlansKeys.productionPlan(prevState.id), prevState, error);
        }
      },
      onSuccess: () => {
        queryClient.invalidateQueries({
          queryKey: productionPlansKeys.shelfItems(productionPlanId),
        });
      },
    },
  );

  return (
    <div className={cx(localStyles.employeeHeader, "d-flex align-items-center")}>
      {employee.employeeWorkingDays.map((employeeWorkingDay, index) => {
        if (index === 0) {
          return (
            <div
              key={employeeWorkingDay.id}
              className={cx(
                localStyles.employeeHeaderInner,
                localStyles.employeeHeaderInnerFirst,
                "d-flex align-items-center",
              )}
            >
              <div>
                <AvatarOrInitials
                  avatarSrc={employee.avatar}
                  firstName={employee.firstName}
                  lastName={employee.lastName}
                  className={cx(localStyles.avatar, localStyles.avatarSmall)}
                />
              </div>
              <div className="mx-2">
                <span>
                  {employeeKind[employee.kind]} {employee.firstName} {employee.lastName}
                </span>
              </div>
              <Button
                kind="secondary"
                size="round-s"
                onClick={() => {}}
                className={cx(
                  styles.button,
                  styles.buttonRound,
                  styles.buttonBackgroundTransparent,
                )}
              >
                <img alt="Pobierz PDF" src={showPdfIcon} />
              </Button>
              <Button
                kind="secondary"
                size="round-s"
                onClick={() =>
                  confirmationModal.open({
                    text: `Czy na pewno chcesz usunąć z planu pracownika "${employee.firstName} ${employee.lastName}"?`,
                    confirmText: "Usuń",
                    callback: () =>
                      removeEmployeeMutation.mutate({
                        id: employee.id,
                        productionPlanId: productionPlanId,
                      }),
                  })
                }
                className={cx(
                  styles.button,
                  styles.buttonRound,
                  styles.buttonBackgroundTransparent,
                )}
              >
                <img alt="Usuń pracownika" src={binIcon} />
              </Button>
              {!employeeWorkingDay.isAvailable && (
                <div className={localStyles.dayOffLabel}>Urlop</div>
              )}
            </div>
          );
        }

        return (
          <div
            key={employeeWorkingDay.id}
            className={cx(localStyles.employeeHeaderInner, "d-flex align-items-center")}
          >
            {!employeeWorkingDay.isAvailable && (
              <div className={cx(localStyles.dayOffLabel, "w-100")}>Urlop</div>
            )}
          </div>
        );
      })}
    </div>
  );
};
