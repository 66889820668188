import weightImg from "assets/images/39g.svg";
import pinImg from "assets/images/32g.svg";
import routeImg from "assets/images/40g.svg";
import billImg from "assets/images/26.svg";
import styles from "./Counters.module.css";
import volumeImg from "assets/images/139.svg";
import cx from "classnames";
import { secondsToTime } from "utilities";
import { useSelector } from "hooks";
import { Route } from "api/routes/models";
import { Gallery } from "components/common/gallery/Gallery";
import { QUERY_LIMIT } from "api/graphhopper/graphhopper";

export const Counters = ({ route }: { route: Route }) => {
  const productCategoriesToCount = useSelector(
    store => store.partials.configuration.productCategoriesToCount,
  );

  const duration = secondsToTime(
    route.ordersPositions.reduce(
      (acc, { meta }) => acc + (meta.delivery.time || 0) + meta.delivery.stopoverTime,
      0,
    ) + Number(route.returnTime),
  );

  const categoryCounter = route.orders.reduce(
    (acc: Record<number | string, number>, order) => {
      order.items.forEach(item => {
        const categoryToCount = productCategoriesToCount.find(
          el => el.id === item.product.category,
        );
        if (categoryToCount) {
          if (!acc[categoryToCount.id]) {
            acc[categoryToCount.id] = 0;
          }
          acc[categoryToCount.id] += item.quantity;
        } else {
          acc.other += item.quantity;
        }
      });
      return acc;
    },
    { other: 0 },
  );

  const weight = route.orders.reduce((acc, order) => acc + Number(order.totalWeight), 0).toFixed(2);
  const volume = route.orders.reduce((acc, order) => acc + Number(order.totalVolume), 0).toFixed(2);

  const carCargo = route.car ? Number(route.car.cargo) * 1000 : 0;

  const carVolume = route.car ? route.car.height * route.car.width * route.car.depth : 0;

  return (
    <div className={styles.container}>
      <div className="px-2 d-flex align-items-center">
        {route.paymentSummary.length > 0 ? (
          <>
            {route.paymentSummary.map(payment => (
              <div className={styles.group} key={payment.currency}>
                <div className={styles.label}>
                  <img src={billImg} alt="kwota" />
                  <span>kwota ({payment.currency})</span>
                </div>
                <div className="d-flex align-items-center">
                  <div className={cx(styles.value, "text-color-blue fs-16 mr-1")}>
                    {payment.summary.toFixed(2).split(".")[0]}
                    <span className="text-color-grey fs-12">
                      .{payment.summary.toFixed(2).split(".")[1]}
                    </span>
                  </div>
                  <span className="text-color-grey fs-12"> {payment.currency}</span>
                </div>
              </div>
            ))}
          </>
        ) : (
          <div className={styles.group}>
            <div className={styles.label}>
              <img src={billImg} alt="kwota" />
              <span>kwota</span>
            </div>
            <div className="d-flex align-items-center">
              <div className={cx(styles.value, "text-color-blue fs-16 mr-1")}>
                0<span className="text-color-grey fs-12">.00</span>
              </div>
            </div>
          </div>
        )}
        <div className={styles.group}>
          <div className={styles.label}>
            <img src={weightImg} alt="waga" />
            <span>waga</span>
          </div>
          <div className="d-flex align-items-center">
            <div className={cx(styles.value, "text-color-blue fs-16 mr-1")}>
              {carCargo === 0 ? (
                <div>
                  {weight.split(".")[0]}
                  <span className="text-color-grey fs-12">
                    .{Number(weight.split(".")[1]).toFixed(1)}
                  </span>
                </div>
              ) : Number(weight) <= carCargo ? (
                <div>
                  {weight.split(".")[0]}
                  <span className="text-color-grey fs-12">
                    .{Number(weight.split(".")[1]).toFixed(1)}
                  </span>
                  <span className="text-color-grey fs-12">/{carCargo} kg</span>
                </div>
              ) : (
                <div>
                  <span className="text-color-red">{weight.split(".")[0]}</span>
                  <span className="text-color-red fs-12">
                    .{Number(weight.split(".")[1]).toFixed(1)}
                  </span>
                  <span className="text-color-grey fs-12">/{carCargo} kg</span>
                </div>
              )}
            </div>
          </div>
        </div>
        <div className={styles.group}>
          <div className={styles.label}>
            <img src={volumeImg} alt="kubatura" />
            <span>kubatura</span>
          </div>
          <div className="d-flex align-items-center">
            <div className={cx(styles.value, "text-color-blue fs-16 mr-1")}>
              {carVolume === 0 ? (
                <div>
                  {volume.split(".")[0]}
                  <span className="text-color-grey fs-12">
                    .{Number(volume.split(".")[1]).toFixed(1)}
                  </span>
                </div>
              ) : Number(volume) <= carVolume ? (
                <div>
                  {volume.split(".")[0]}
                  <span className="text-color-grey fs-12">
                    .{Number(volume.split(".")[1]).toFixed(1)}
                  </span>
                  <span className="text-color-grey fs-12">/{carVolume.toFixed(1)} m3</span>
                </div>
              ) : (
                <div>
                  <span className="text-color-red fs-12">{volume.split(".")[0]}</span>
                  <span className="text-color-red fs-12">
                    .{Number(volume.split(".")[1]).toFixed(1)}
                  </span>
                  <span className="text-color-grey fs-12">/{carVolume.toFixed(1)} m3</span>
                </div>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="border-left border-right mx-3 px-3 d-flex align-items-center">
        <div className={styles.group}>
          <div className={styles.label}>
            <img src={pinImg} alt="punkty" />
            <span>punkty</span>
          </div>
          <div className="d-flex align-items-center ">
            <div className={styles.value}>
              <strong className={cx({ [styles.exceeded]: route.orders.length > QUERY_LIMIT })}>
                {route.orders.length}
              </strong>
              <span
                className={cx({
                  [styles.hidden]: route.orders.length <= QUERY_LIMIT,
                })}
              >
                /{QUERY_LIMIT}
              </span>
            </div>
          </div>
        </div>
        <div className={styles.group}>
          <div className={styles.label}>
            <img src={routeImg} alt="długość trasy" />
            <span>długość trasy</span>
          </div>
          <div className="d-flex align-items-center">
            <div className={styles.value}>{(route.length / 1000).toFixed(0)}km</div>
          </div>
        </div>
        <div className={cx(styles.group, "mr-0")}>
          <div className={styles.label}>
            <img src={routeImg} alt="czas przejazdu" />
            <span>czas przejazdu</span>
          </div>
          <div className="d-flex align-items-center">
            <div className={styles.value}>
              {duration.hours}h {duration.minutes}m
            </div>
          </div>
        </div>
      </div>

      <Gallery>
        {productCategoriesToCount.map(category => {
          if (!categoryCounter[category.id]) return null;

          return (
            <Group
              label={category.name}
              value={`${categoryCounter[category.id] ?? 0} szt.`}
              imgSrc={category.picture || ""}
              key={category.id}
            />
          );
        })}
        {productCategoriesToCount.length && categoryCounter.other !== 0 ? (
          <Group label="inne" value={`${categoryCounter.other ?? 0} szt.`} />
        ) : null}
      </Gallery>
    </div>
  );
};

function Group({
  imgSrc,
  label,
  value,
}: {
  imgSrc?: string;
  label: string;
  value: string | number;
}) {
  return (
    <div className={styles.group}>
      <div className={styles.label}>
        {imgSrc && <img src={imgSrc} alt="" />}
        <span className={cx({ "ml-0": !imgSrc })}>{label}</span>
      </div>
      <div className="d-flex align-items-center">
        <div className={cx(styles.value, { "ml-0": !imgSrc })}>{value}</div>
      </div>
    </div>
  );
}
