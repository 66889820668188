import { Button } from "components/common";
import cx from "classnames";
import WhiteChairIcon from "assets/images/chair.svg";
import DayColumnIcon from "assets/images/day_column.svg";
import FramePersonIcon from "assets/images/frame_person.svg";
import WhiteCarIcon from "assets/images/151w.svg";
import localStyles from "./LeftToolbar.module.css";
import styles from "../../ProductionPlanDetails.module.css";
import { useToggle } from "hooks";
import { AddEmployeeModal } from "../addEmployeeModal/AddEmployeeModal";
import { ShelfFilters } from "api/new-production-plans/models";
import { AddWorkingDayModal } from "../addWorkingDayModal/AddWorkingDayModal";
import { AddRoutesFiltersModal } from "../addRoutesFiltersModal/AddRoutesFiltersModal";
import { useState } from "react";
import { ProductionPlan } from "api/new-production-plans/models";

interface Props {
  productionPlan: ProductionPlan;
  refetch: () => void;
  shelfFilters: ShelfFilters;
  setShelfFilters: React.Dispatch<React.SetStateAction<ShelfFilters>>;
}

export interface CheckedElement {
  checked: boolean;
  id: number;
}

export const LeftToolbar = ({ productionPlan, refetch, shelfFilters, setShelfFilters }: Props) => {
  const [checked, setChecked] = useState<CheckedElement[]>([]);

  const addEmployeeModal = useToggle();
  const addWorkingDayModal = useToggle();
  const addRoutesFiltersModal = useToggle();

  const closeRoutesFiltesModal = (): void => {
    setChecked([]);
    addRoutesFiltersModal.close();
  };

  return (
    <div className={localStyles.leftToolbar}>
      <Button
        kind="secondary"
        size="small"
        onClick={addEmployeeModal.open}
        className={cx(styles.button, localStyles.buttonSquare)}
      >
        <img alt="" src={FramePersonIcon} />
      </Button>
      <Button
        kind="secondary"
        size="small"
        onClick={addWorkingDayModal.open}
        className={cx(styles.button, localStyles.buttonSquare)}
      >
        <img alt="" src={DayColumnIcon} />
      </Button>
      <Button
        kind="secondary"
        size="small"
        onClick={addRoutesFiltersModal.open}
        className={cx(styles.button, localStyles.buttonSquare)}
      >
        <img alt="Filtry tras" src={WhiteCarIcon} />
      </Button>
      <Button
        kind="secondary"
        size="small"
        onClick={() => {}}
        className={cx(styles.button, localStyles.buttonSquare)}
      >
        <img alt="" src={WhiteChairIcon} />
      </Button>
      {addEmployeeModal.isOpen && (
        <AddEmployeeModal
          assignedEmployees={productionPlan.employees}
          close={addEmployeeModal.close}
          productionPlanId={productionPlan.id}
          refetch={refetch}
        />
      )}
      {addWorkingDayModal.isOpen && (
        <AddWorkingDayModal
          close={addWorkingDayModal.close}
          productionPlanId={productionPlan.id}
          refetch={refetch}
          workingDays={productionPlan.days}
        />
      )}
      {addRoutesFiltersModal.isOpen && (
        <AddRoutesFiltersModal
          close={closeRoutesFiltesModal}
          checkedRoutes={checked}
          setCheckedRoutes={setChecked}
          shelfFilters={shelfFilters}
          setShelfFilters={setShelfFilters}
        />
      )}
    </div>
  );
};
