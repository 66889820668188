import { DeliveryMethodType } from "api/orders/models";
import { Seller } from "api/sellers/models";
import { UUID } from "api/types";
import { VatRate } from "api/vat-rates/models";

export interface Labels {
  warehouse: {
    orientation: string;
    height: string;
    width: string;
    showQrCode: boolean;
    showBarcode: boolean;
    showPicture: boolean;
    showLogo: boolean;
  };
  production: {
    orientation: string;
    height: string;
    width: string;
    showQrCode: boolean;
    showBarcode: boolean;
    showPicture: boolean;
  };
  transport: {
    orientation: string;
    height: string;
    width: string;
    showQrCode: boolean;
    showBarcode: boolean;
    showPicture: boolean;
    showLogo: boolean;
  };
}

export type DeadlineDayOptions =
  | "TODAY"
  | "TOMORROW"
  | "AFTER_TOMORROW"
  | "NEAREST_MONDAY"
  | "NEAREST_FRIDAY";

export interface ConfirmationDateOptions {
  id: string;
  deadlineTime: string;
  deadlineDayOption: DeadlineDayOptions;
}

export interface PostConfirmationDateOptions {
  deadlineTime: string;
  deadlineDayOption: string;
}

export interface Transport {
  addToLeftRange: number;
  addToRightRange: number;
  defaultPauseBetweenRoutePoint: number;
  defaultRouteStartTime: string;
  defaultDeliveryMethod: DeliveryMethodType;
  driverDeliveryHourEnd: string;
  includeLastPointInOptimization: boolean;
  showDailyProductionInfo: boolean;
  showStopoverOnRoutePdf: boolean;
  driverDeliveryHourStart: string;
  driverWorkHoursAlign: boolean;
  driverWorkHoursFromMaxSpacing: number;
  driverWorkHoursFromMinSpacing: number;
  hasCategoriesCountersOnMap: boolean;
  showOnMapOrdersOnWayToWarehouse: boolean;
  hasDeadlineConfirmationDate: boolean;
  countVolumeAndWeighOnMapBy: string;
  options: ConfirmationDateOptions[];
  id: number;
  lastModified: string;
  roundType: "NONE" | "HALF" | "FULL" | "FULL_HOUR_DOWN";
}

export interface Production {
  createProductionOrdersAutomatically: boolean;
  id: number;
  lastModified: string;
  showDailyProductionInfo: boolean;
}

export interface RightPanelDisplay {
  deliverySection: {
    email: boolean;
    login: boolean;
    manualDeliveryPoint: boolean;
  };
  hasProducedItems: boolean;
  invoiceData: boolean;
  messageToSeller: boolean;
  comments: boolean;
  warehousesDeliveryDateSectionPresence: boolean;
  routeSection: {
    signature: boolean;
    showNameInsteadOfSignature: boolean;
    deliveryMethod: boolean;
    collectionDate: boolean;
    minimumDeliveryDate: boolean;
    clientConfirmation: boolean;
    exportSpecificationButton: boolean;
    driver: boolean;
    departureDate: boolean;
    length: boolean;
    shippingService: boolean;
    hasCarrying: boolean;
    previousRoutes: boolean;
    previousGroups: boolean;
    isShipped: boolean;
    isDelivered: boolean;
    isSettled: boolean;
  };
  productsSection: {
    offerNumber: boolean;
    notes: boolean;
    externalNumber: boolean;
    isPrepared: boolean;
    hasReservation: boolean;
    availabilityButton: boolean;
    listingsComparisonButton: boolean;
  };
  contactSection: {
    sms: boolean;
    email: boolean;
    isCallRequired: boolean;
    isWaitingForCustomerCall: boolean;
  };
  paymentSection: {
    currency: boolean;
    type: boolean;
    deliveryAmount: boolean;
    discount: boolean;
    status: boolean;
    isReturn: boolean;
    isInvoiceRequired: boolean;
    isVerificationRequired: boolean;
    isExternalRelease: boolean;
    hasTransportBeenInvoiced: boolean;
    hasItemsBeenInvoiced: boolean;
    hasTransportBeenSettled: boolean;
    hasItemsBeenSettled: boolean;
  };
}

export type CustomerSalesAccountLabelsSetting =
  | "ONLY_CUSTOMER_LABELS_VISIBLE_IN_ORDER_LIST"
  | "ONLY_SALES_ACCOUNT_LABELS_VISIBLE_IN_ORDER_LIST"
  | "CUSTOMER_LABELS_VISIBLE_IF_NOT_SALES_ACCOUNT_VISIBLE_IN_ORDER_LIST"
  | "SALES_ACCOUNT_LABELS_VISIBLE_IF_NOT_CUSTOMER_VISIBLE_IN_ORDER_LIST"
  | "CUSTOMER_AND_SALES_ACCOUNT_LABELS_VISIBLE_IN_ORDER_LIST"
  | "CUSTOMER_AND_SALES_ACCOUNT_LABELS_NOT_VISIBLE_IN_ORDER_LIST";

export interface Sales {
  b2b: {
    paymentType: {
      cashOnDelivery: boolean;
      online: boolean;
      installment: boolean;
      debitCard: boolean;
    };
    deliveryMethods: { external: boolean; personalCollection: boolean; own: boolean };
    isPhoneRequired: boolean;
    isEmailRequired: boolean;
    rightPanelDisplay: RightPanelDisplay;
  };
  hasSignatureOrderNumber: boolean;
  isEmailRequired: boolean;
  isPhoneRequired: boolean;
  rightPanelDisplay: RightPanelDisplay;
  customerSalesAccountLabelsInOrderList: CustomerSalesAccountLabelsSetting;
  customerSalesAccountLabelsInOrderListB2b: CustomerSalesAccountLabelsSetting;
  listDisplay: {
    signature: boolean;
    name: boolean;
    arrivalDate: boolean;
    salesAccountName: boolean;
    priceListName: boolean;
    clientName: boolean;
    deliveryAddress: boolean;
    orderStatus: boolean;
    numberOfLeftDays: boolean;
    invoicePaidStatus: boolean;
    phoneContactStatus: boolean;
    assignedUser: boolean;
  };
  deliveryMethods: { external: boolean; personalCollection: boolean; own: boolean };
  paymentType: {
    installment: boolean;
    debitCard: boolean;
    online: boolean;
    cashOnDelivery: boolean;
  };
}

type VerificationStatus = "NOT_VERIFIED" | "VERIFICATION_ERROR" | "VERIFIED";

export interface Communication {
  contactPerson: {
    email: string;
    name: string;
    phone: string;
  };
  sms: {
    has2WaySms: boolean;
    hasSmsService: boolean;
    senderName: string;
    dedicatedSenderPhone: string;
    login: string;
    password: string;
    token: string;
    status: VerificationStatus;
    hasUtf: boolean;
    verificationMessage: string;
    isSmsUsingDedicatedPhoneNumber: boolean;
    sendTwoWaySmsOnDefault: boolean;
  };
  email: {
    emailApiKey: string;
    emailForManufacturer: string;
    emailSecretKey: string;
    emailSmtpAccount: string;
    hasEmailForManufacturers: boolean;
    hasEmailForRoutes: boolean;
    hasEmailService: boolean;
    emailForRoute: string;
    sendEmailWhenOrderConfirmed: boolean;
    sendEmailWhenDeliveryDateConfirmed: boolean;
    status: VerificationStatus;
  };
}

export interface ExchangeRates {
  currency: {
    currency: string;
    id: UUID;
  };
  id: UUID;
  settings: UUID;
}

export interface CreateCurrencyToWatch {
  currency: UUID;
  settings: UUID;
}

export interface Warehouse {
  autoCloseWarehouseDocuments: boolean;
  requiredSuperuserToCloseWarehouseDocuments: boolean;
}

export interface TradingDocument {
  automaticallyAddTransportToTradingDocument: boolean;
  createAutomaticallyDocumentsWhenOrderIsConfirmed: boolean;
  defaultSellerForAutomaticallyDocuments: Seller | null;
  defaultTransportSellerForAutomaticallyDocuments: Seller | null;
  id: UUID;
  transportDocumentItemName: string;
  transportOnSeparateTradingDocument: boolean;
  vatRateForTransport: VatRate | null;
}

export interface MiloSettings {
  labels: Labels;
  transport: Transport;
  production: Production;
  sales: Sales;
  communication: Communication;
  exchangeRates: ExchangeRates;
  warehouse: Warehouse;
  tradingDocument: TradingDocument;
}

export enum Tab {
  sales = "Sprzedaż",
  transport = "Transport",
  production = "Produkcja",
  labels = "Etykiety",
  communication = "Powiadomienia",
  exchangeRates = "Kursy walut",
  warehouse = "Magazyn",
  tradingDocument = "Dokument handlowy",
}

export interface DeliveryHoursTestPayload {
  plannedDeliveryTime: string;
  settings: {
    driverDeliveryHourStart: string;
    driverDeliveryHourEnd: string;
    roundType: string;
    addToLeftRange: number;
    addToRightRange: number;
    driverWorkHoursAlign: boolean;
    driverWorkHoursFromMinSpacing: number;
    driverWorkHoursFromMaxSpacing: number;
  };
}

export interface DedicatedPhoneNumber {
  communicationSettings: number;
  countryCode: string;
  id: number;
  isDefault: boolean;
  phone: string;
  prefix: string;
}

export type CreateDedicatedPhoneNumber = Omit<DedicatedPhoneNumber, "id" | "communicationSettings">;
