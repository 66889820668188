import localStyles from "./AddWorkingDayModal.module.css";
import cx from "classnames";
import whitePlusIcon from "assets/images/20w.svg";
import checkIcon from "assets/images/checkDarkBlue.svg";
import { Modal } from "components/utils";
import { WorkingDay } from "api/new-production-plans/models";
import { useState } from "react";
import { dateFns, getAnyErrorKey } from "utilities";
import { Button, useToastr } from "components/common";
import { DayToPick } from "./subcomponents/dayToPick/DayToPick";
import { useMutation } from "hooks/useMutation";
import { addWorkingDaysToProductionPlan } from "api/new-production-plans/calls";
import { useQueryClient } from "react-query";
import { productionPlansKeys } from "api/new-production-plans/keys";

interface Props {
  close: () => void;
  productionPlanId: string;
  refetch: () => void;
  workingDays: WorkingDay[];
}

export interface DayToPick {
  date: string;
  day: string;
  name: string;
  picked: boolean;
}

const numberOfDays = 5;

export type DayType = "PREVIOUS" | "NEXT" | "TODAY";

const days = ["Niedziela", "Poniedziałek", "Wtorek", "Środa", "Czwartek", "Piątek", "Sobota"];

export const AddWorkingDayModal = ({ close, productionPlanId, refetch, workingDays }: Props) => {
  const queryClient = useQueryClient();
  const toastr = useToastr();
  const [pickedDates, setPickedDates] = useState<string[]>([]);
  const [today, setToday] = useState<DayToPick>({
    date: dateFns.format(new Date(), "yyyy-MM-dd"),
    day: days[new Date().getDay()],
    name: "dzisiaj",
    picked: false,
  });
  const [previousDaysToPick, setPreviousDaysToPick] = useState<DayToPick[]>(() => {
    const previousDays = [];
    const currentDate = new Date();

    for (let i = 1; i <= numberOfDays; i++) {
      const previousDate = new Date(currentDate);
      previousDate.setDate(currentDate.getDate() - i);
      const dayToPick: DayToPick = {
        date: dateFns.format(previousDate, "yyyy-MM-dd"),
        day: days[previousDate.getDay()],
        name: i === 1 ? "wczoraj" : "",
        picked: false,
      };
      previousDays.push(dayToPick);
    }

    return previousDays;
  });
  const [nextDaysToPick, setNextDaysToPick] = useState<DayToPick[]>(() => {
    const nextDays = [];
    const currentDate = new Date();
    for (let i = 1; i <= numberOfDays; i++) {
      const nextDate = new Date(currentDate);
      nextDate.setDate(currentDate.getDate() + i);
      const dayToPick: DayToPick = {
        date: dateFns.format(nextDate, "yyyy-MM-dd"),
        day: days[nextDate.getDay()],
        name: i === 1 ? "jutro" : "",
        picked: false,
      };
      nextDays.push(dayToPick);
    }

    return nextDays;
  });

  const addMoreDays = () => {
    const previousDays = [...previousDaysToPick];
    const nextDays = [...nextDaysToPick];
    const previousLastDate = new Date(previousDays[previousDays.length - 1].date);
    const nextLastDate = new Date(nextDays[nextDays.length - 1].date);

    for (let i = 1; i <= numberOfDays; i++) {
      const previousDate = new Date(previousLastDate);
      previousDate.setDate(previousLastDate.getDate() - i);
      const nextDate = new Date(nextLastDate);
      nextDate.setDate(nextLastDate.getDate() + i);

      const previousDayToPick: DayToPick = {
        date: dateFns.format(previousDate, "yyyy-MM-dd"),
        day: days[previousDate.getDay()],
        name: "",
        picked: false,
      };
      previousDays.push(previousDayToPick);

      const nextDayToPick: DayToPick = {
        date: dateFns.format(nextDate, "yyyy-MM-dd"),
        day: days[nextDate.getDay()],
        name: "",
        picked: false,
      };
      nextDays.push(nextDayToPick);
    }

    setPreviousDaysToPick(previousDays);
    setNextDaysToPick(nextDays);
  };

  const togglePickedDate = (date: string, kind: DayType): void => {
    if (kind === "PREVIOUS") {
      const updatedPreviousDays = previousDaysToPick.map(day =>
        day.date === date ? { ...day, picked: !day.picked } : day,
      );
      setPreviousDaysToPick(updatedPreviousDays);
    } else if (kind === "NEXT") {
      const updatedNextDays = nextDaysToPick.map(day =>
        day.date === date ? { ...day, picked: !day.picked } : day,
      );
      setNextDaysToPick(updatedNextDays);
    } else {
      setToday(today => {
        return { ...today, picked: !today.picked };
      });
    }
  };

  const isDateAlreadyInPlan = (date: string): boolean => {
    const isDateInPlan = workingDays.find(workingDay => workingDay.date === date);
    if (isDateInPlan) return true;
    return false;
  };

  const addWorkingDaysToProductionPlanMutation = useMutation(
    ({ workingDays, productionPlanId }: { workingDays: string[]; productionPlanId: string }) =>
      addWorkingDaysToProductionPlan({
        stageManufacturingPlanId: productionPlanId,
        workingDays: workingDays,
      }),
    {
      onSuccess: () => {
        refetch();
        queryClient.invalidateQueries({
          queryKey: productionPlansKeys.shelfItems(productionPlanId),
        });
        close();
      },
      onError: error => {
        close();
        toastr.open({
          type: "warning",
          title: "Oj, coś nie tak...",
          text: getAnyErrorKey(error),
        });
      },
    },
  );

  return (
    <Modal isOpen={true} close={close} overrides={{ container: { className: localStyles.modal } }}>
      <div>
        <div className={cx(localStyles.title, "mt-2 mb-4")}>Dodaj dni pracy</div>
        <div
          className={cx(localStyles.today, "mb-2", {
            [localStyles.todayActive]: today.picked && !isDateAlreadyInPlan(today.date),
            [localStyles.todayInPlan]: isDateAlreadyInPlan(today.date),
          })}
          onClick={() => {
            if (!isDateAlreadyInPlan(today.date)) {
              togglePickedDate(today.date, "TODAY");
              const isPicked = pickedDates.find(el => el === today.date);
              if (!isPicked) {
                setPickedDates(pickedDates => {
                  return [...pickedDates, today.date];
                });
              } else {
                setPickedDates(pickedDates => {
                  const updatedPickedDates = pickedDates.filter(date => date !== today.date);
                  return updatedPickedDates;
                });
              }
            }
          }}
        >
          <div className="d-flex alignitems-center justify-content-center">
            {isDateAlreadyInPlan(today.date) && (
              <img alt="" src={checkIcon} className="mr-2 pb-1" />
            )}
            <span>
              {today.day} ({today.name})
            </span>
          </div>
          <div className="d-flex justify-content-center fs-18">
            <span>{today.date}</span>
          </div>
        </div>
        <div className="d-flex align-items-center">
          <div className="w-50 p-3">
            {previousDaysToPick.map(previousDay => (
              <DayToPick
                day={previousDay}
                isDateAlreadyInPlan={isDateAlreadyInPlan}
                kind="PREVIOUS"
                pickedDates={pickedDates}
                setPickedDates={setPickedDates}
                togglePickedDate={togglePickedDate}
              />
            ))}
          </div>
          <div className="w-50 p-3">
            {nextDaysToPick.map(nextDay => (
              <DayToPick
                day={nextDay}
                isDateAlreadyInPlan={isDateAlreadyInPlan}
                kind="NEXT"
                pickedDates={pickedDates}
                setPickedDates={setPickedDates}
                togglePickedDate={togglePickedDate}
              />
            ))}
          </div>
        </div>
        <div className="mb-3">
          <Button kind="secondary-grey" size="small" className="w-100" onClick={addMoreDays}>
            Pokaż kolejne {numberOfDays} dni
          </Button>
        </div>
        <div className={cx(localStyles.submitBtnContainer, "pt-3")}>
          <Button
            className="w-100"
            disabled={pickedDates.length === 0}
            kind="primary"
            onClick={() =>
              addWorkingDaysToProductionPlanMutation.mutate({
                productionPlanId: productionPlanId,
                workingDays: pickedDates,
              })
            }
            size="medium"
          >
            <div className="d-flex align-items-center">
              <img alt="" src={whitePlusIcon} className="mr-1" />
              <span>Dodaj dni </span>
              {pickedDates.length > 0 && <span className="ml-1">({pickedDates.length})</span>}
            </div>
          </Button>
        </div>
      </div>
    </Modal>
  );
};
