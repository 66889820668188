import { ProductionPlan, WorkingDay } from "api/new-production-plans/models";
import localStyles from "./DateBar.module.css";
import styles from "../../ProductionPlanDetails.module.css";
import cx from "classnames";
import { dateFns } from "utilities";
import calendarIcon from "assets/images/4.svg";
import deleteIcon from "assets/images/delete.svg";
import { Button } from "components/common";
import { useMutation } from "hooks/useMutation";
import { removeWorkingDayFromProductionPlan } from "api/new-production-plans/calls";
import { useConfirmModal, useQueryUtils } from "hooks";
import { productionPlansKeys } from "api/new-production-plans/keys";
import { useQueryClient } from "react-query";

interface Props {
  days: WorkingDay[];
  productionPlanId: string;
  refetch: () => void;
}

const daysOfTheWeek = {
  MONDAY: "Poniedziałek",
  TUESDAY: "Wtorek",
  WEDNESDAY: "Środa",
  THURSDAY: "Czwartek",
  FRIDAY: "Piątek",
  SATURDAY: "Sobota",
  SUNDAY: "Niedziela",
};

export const DateBar = ({ days, productionPlanId, refetch }: Props) => {
  const { handleMutate, rollback } = useQueryUtils();
  const queryClient = useQueryClient();
  const confirmationModal = useConfirmModal();

  const removeWorkingDayMutation = useMutation(
    ({ workingDay, productionPlanId }: { workingDay: string; productionPlanId: string }) =>
      removeWorkingDayFromProductionPlan({
        stageManufacturingPlanId: productionPlanId,
        workingDays: [workingDay],
      }),
    {
      onMutate: ({
        workingDay,
        productionPlanId,
      }: {
        workingDay: string;
        productionPlanId: string;
      }) => {
        const prevState = handleMutate<ProductionPlan>(
          productionPlansKeys.productionPlan(productionPlanId),
          draft => {
            draft.days = draft.days.filter(day => day.date !== workingDay);
            draft.employees = draft.employees.map(employee => {
              const updatedEmployeeWorkingDays = employee.employeeWorkingDays.filter(
                employeeWorkingDay => employeeWorkingDay.date !== workingDay,
              );

              return { ...employee, employeeWorkingDays: updatedEmployeeWorkingDays };
            });
          },
        );
        return prevState;
      },
      onError: (error, _, prevState) => {
        if (prevState) {
          rollback(productionPlansKeys.productionPlan(prevState.id), prevState, error);
        }
      },
      onSuccess: () => {
        refetch();
        queryClient.invalidateQueries({
          queryKey: productionPlansKeys.shelfItems(productionPlanId),
        });
      },
    },
  );

  return (
    <div className={localStyles.dateBar}>
      {days.map((day, index) => (
        <div
          key={day.id}
          className={cx(
            localStyles.dateColumn,
            "d-flex align-items-center justify-content-between",
            {
              [localStyles.dateColumnSaturday]: day.dayOfTheWeek === "SATURDAY",
              [localStyles.dateColumnSunday]: day.dayOfTheWeek === "SUNDAY",
              [localStyles.dateColumnHoliday]: day.isHoliday,
            },
          )}
        >
          <div className={cx("d-flex align-items-center", styles.gap2)}>
            <strong>Dzień {index + 1}</strong>
            <span>{daysOfTheWeek[day.dayOfTheWeek]}</span>
            <span className={localStyles.separator} />
            <strong className={localStyles.date}>
              {dateFns.format(new Date(day.date), "dd/MM/yyyy")}
            </strong>
            <img alt="" src={calendarIcon} className="ml-1 pb-1" />
          </div>
          <Button
            kind="secondary"
            size="round-s"
            onClick={() =>
              confirmationModal.open({
                text: `Czy na pewno chcesz usunąć z planu dzień "${
                  daysOfTheWeek[day.dayOfTheWeek]
                }, ${dateFns.format(new Date(day.date), "dd/MM/yyyy")}"?`,
                confirmText: "Usuń",
                callback: () =>
                  removeWorkingDayMutation.mutate({
                    workingDay: day.date,
                    productionPlanId: productionPlanId,
                  }),
              })
            }
            className={cx(
              styles.button,
              styles.buttonRound,
              styles.buttonBackgroundTransparentWhite,
              "mr-2",
            )}
          >
            <img alt="" src={deleteIcon} />
          </Button>
        </div>
      ))}
    </div>
  );
};
