import { useProductionPlan } from "api/new-production-plans/hooks";
import { CommonError, MockupLoader } from "components/utils";
import { RouteComponentProps } from "react-router-dom";
import { Header } from "./subcomponents/header/Header";
import { LeftToolbar } from "./subcomponents/leftToolbar/LeftToolbar";
import styles from "./ProductionPlanDetails.module.css";
import { DateBar } from "./subcomponents/dateBar/DateBar";
import { EmployeeRow } from "./subcomponents/employeeRow/EmployeeRow";
import { Shelf } from "./shelf/Shelf";
import { useEffect, useState } from "react";
import { ShelfFilters } from "api/new-production-plans/models";

interface Props {
  routeComponentsProps: RouteComponentProps<{ id: string }>;
}

export const ProductionPlanDetails = ({ routeComponentsProps: { match } }: Props) => {
  const { data: productionPlan, isLoading, error, refetch } = useProductionPlan(match.params.id);
  // state for shelf filters manipulation in modals
  const [shelfFilters, setShelfFilters] = useState<ShelfFilters>({
    orders: {},
    orderGroups: {},
    products: {},
    routes: {},
  });

  useEffect(() => {
    if (productionPlan) {
      const updatedShelfFilters = {
        orders: productionPlan.shelfFilters.orders,
        orderGroups: productionPlan.shelfFilters.orderGroups,
        products: productionPlan.shelfFilters.products,
        routes: productionPlan.shelfFilters.routes,
      };
      setShelfFilters(updatedShelfFilters);
    }
  }, [productionPlan]);

  if (error) {
    <CommonError status={error._httpStatus_} />;
  }

  if (isLoading) {
    return <MockupLoader type="smallList" />;
  }

  if (!productionPlan) {
    return null;
  }

  return (
    <div className="position-relative">
      <Header productionPlan={productionPlan} />
      <div className={styles.contentWrapper}>
        <LeftToolbar
          productionPlan={productionPlan}
          refetch={refetch}
          shelfFilters={shelfFilters}
          setShelfFilters={setShelfFilters}
        />
        <div className={styles.scrollXWrapper}>
          <div className={styles.contentGroup}>
            <div className={styles.cols}>
              <DateBar
                days={productionPlan.days}
                productionPlanId={productionPlan.id}
                refetch={refetch}
              />
              <div className={styles.scrollYWrapper}>
                {productionPlan.employees.map(employee => (
                  <EmployeeRow
                    key={employee.id}
                    employee={employee}
                    productionPlan={productionPlan}
                  />
                ))}
              </div>
            </div>
          </div>
        </div>
      </div>
      <Shelf productionPlan={productionPlan} shelfFilters={shelfFilters} />
    </div>
  );
};
